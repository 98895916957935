import {
  FETCH_DICTIONARY_ERROR,
  FETCH_DICTIONARY_REQUEST,
  FETCH_DICTIONARY_SUCCESS,
  SET_MODERATION_DATA,
} from '../constants/action-types';

const initialState = {
  isLoading: false,
  languages: [],
  motivations: [],
  worlds: [],
  vibes: [],
  questions: [],
  cities: [],
  occupations: [],
  validations: {},
  moderationData: null,
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DICTIONARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        ...action.payload,
      };
    }
    case FETCH_DICTIONARY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        languages: [],
        motivations: [],
        worlds: [],
        occupations: [],
      };
    }
    case FETCH_DICTIONARY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }
    case SET_MODERATION_DATA: {
      return {
        ...state,
        moderationData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default global;
