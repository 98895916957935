import React from "react";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import "../styles/LoginForm.scss";
import { requestLogin } from "../api";

import { useAuth, useProvideAuth } from "../hooks/useAuth";
import Button from "../components/Button";

const Page = () => {
  const inputRef = React.useRef();
  const [requested, setRequested] = React.useState(false);
  const [error, setError] = React.useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  let from = location.state?.from?.pathname || "/dashboard";

  React.useEffect(() => {
    if (token && token.length > 0) {
      auth.login(token, () => {
        navigate(from, { replace: true });
      });
    } else {
      if (auth.token) {
        navigate(from, { replace: true });
      }
    }
  }, []);

  const [data, updateDate] = React.useState({
    email: "",
    disabled: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await requestLogin(data.email);
      setRequested(true);
    } catch (error) {
      setRequested(false);
      setError(true);
    }
  };
  const handleChange = (e) => {
    updateDate({
      email: e.target.value.trim(),
      disabled: !e.target.validity.valid,
    });
    setError(false);
  };

  const disabled = React.useMemo(() => {
    return data.disabled || error;
  }, [data, error]);

  return requested ? (
    <div className="email-requested">
      <p>
        Good!
        <br />
        We’ve sent you the magic link.
        <br />
        Please check your email.
      </p>
    </div>
  ) : (
    <div className="login-form">
      <div className="field">
        <input
          id="useremail"
          name="useremail"
          type="email"
          placeholder="your team email"
          required
          ref={inputRef}
          onChange={handleChange}
        />
        <div className="label">
          <label htmlFor="useremail">E-mail</label>
          {error && <span className="error">Can’t find this email</span>}
        </div>
      </div>
      <Button onClick={handleSubmit} disabled={disabled}>
        Sign in
      </Button>
    </div>
  );
};

export default Page;
