import React, { useContext, createContext, useState } from 'react';
// import './App.css';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

import Header from './Header';
import { useAuth } from "../hooks/useAuth";
import Loader from './Loader'

export default function Layout() {
  let auth = useAuth();
  if (auth.loading) {
    return <Loader />
  }

  return (
    <>
      <Header />
      <div className='app-outlet'>
        <Outlet />
      </div>
    </>
  );
}