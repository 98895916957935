import moment from 'moment';
import React, { useContext, createContext, useState } from "react";

export default function Duration({type = 'black', style={}, time}) {
  const intervalRef = React.useRef();
  const interval = 1000;
  const now = moment().unix();
  time = moment(time).unix();

  const diffTime = now - time;
  const [duration, setDuration] = useState(moment.duration(diffTime * 1000, 'milliseconds'));

  //const [duration, setDuration] = useState(moment.duration(diffTime * 1000, 'milliseconds'));
  React.useEffect(() => {
    intervalRef.current = setTimeout(() => {
      const new_duration = moment.duration(
        duration + interval,
        'milliseconds',
      );
      setDuration(new_duration);
    }, interval);

    return () => clearInterval(intervalRef.current);
  }, [duration]);

  const hours = Math.floor(duration.asHours())
      .toString()
      .padStart(2, 0);
    const mins = (Math.floor(duration.asMinutes()) - hours * 60)
      .toString()
      .padStart(2, 0);
    const secs = (
      Math.floor(duration.asSeconds()) -
      hours * 60 * 60 -
      mins * 60
    )
      .toString()
      .padStart(2, 0);

  return(
    <div className={`caption ${type}`} style={style}>
      {hours}:{mins}:{secs}
    </div>
  )
}