import {
  FETCH_DICTIONARY_ERROR,
  FETCH_DICTIONARY_REQUEST,
  FETCH_DICTIONARY_SUCCESS,
  SET_MODERATION_DATA,
} from '../constants/action-types';
import {requestDictionary} from '../api';

export const fetchDictionaryError = () => ({
  type: FETCH_DICTIONARY_ERROR,
  payload: {error: true},
});

export const fetchDictionaryRequest = () => ({
  type: FETCH_DICTIONARY_REQUEST,
  payload: {isLoading: true},
});

export const fetchDictionarySuccess = data => ({
  type: FETCH_DICTIONARY_SUCCESS,
  payload: data,
});

export const fetchDictionary = () => dispatch => {
  dispatch(fetchDictionaryRequest());
  return requestDictionary()
    .then(data => {
      dispatch(fetchDictionarySuccess(data));
    })
    .catch(e => {
      dispatch(fetchDictionaryError());
    });
};

export const setModerationData = payload => ({
  type: SET_MODERATION_DATA,
  payload,
});

