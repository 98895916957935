import styles from "./styles.module.scss";

export default function Button(props) {
  const {
    type = "red",
    children,
    onClick = () => {},
    disabled,
    className,
    style = {},
    loading = false,
    standard,
  } = props;
  return (
    <div
      className={[
        styles.button,
        styles[type],
        disabled ? styles.disabled : "",
        standard ? styles.standard : "",
        className,
      ].join(" ")}
      onClick={(e) => {
        !disabled && onClick(e);
      }}
      style={style}
    >
      {loading ? <div className={styles.loader} /> : children}
    </div>
  );
}
