import {combineReducers} from 'redux';

import global from './global';

// Root Reducer
const rootReducer = combineReducers({
  global,
});

export default rootReducer;
