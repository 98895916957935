import React from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { loginUser, requestProfile, logoutUser } from "../api";
import { useDispatch } from "react-redux";
import { fetchDictionary } from "../actions/global";
const AuthContext = createContext();

export const AuthProvider1 = ({ children }) => {
  const [token, setToken] = useLocalStorage("userToken", null);
  const navigate = useNavigate();

  const login = async (data) => {
    const result = await loginUser(data);
    setToken(result.token);
    navigate("/dashboard", { replace: true });
  };

  const logout = () => {
    //setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const delay = (duration = 500) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve();
    }, duration);
  });
};

export function useProvideAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(null);
  const [token, setToken] = useLocalStorage("userToken", null);
  const [loading, setLoading] = React.useState(true);
  const login = async (data) => {
    const result = await loginUser(data);
    setToken(result.token);
    await delay(300);
    const u = await requestProfile();
    await dispatch(fetchDictionary());
    setUser(u);
    setLoading(false);
    navigate("/dashboard", { replace: true });
  };
  const logout = async (data) => {
    await logoutUser(data);
    setToken(null);
    setUser(null);
    navigate("/login", { replace: true });
  };

  React.useEffect(() => {
    if (token && !user) {
      setLoading(true);
      requestProfile().then((u) => {
        setUser(u);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    user,
    token,
    login,
    logout,
  };
}
